import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { ReactComponent as AuthLogo } from "assets/images/logo.svg";
import styled from "styled-components";
import { currencySign, formatCurrency, getColor, getInitials } from "helpers";
import Tooltip from "react-simple-tooltip";
import countries from "variables/countries";
import UserDoc from "./UserFile";
import { useDispatch } from "react-redux";
import { userActions } from "actions";

const UserDetails = ({ selectedUser: user, setModal }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    setStatus(user?.active);
    // eslint-disable-next-line
  }, []);

  const changeStatus = (e) => {
    const { value: status } = e.target;
    const boolStatus = status === "true";

    setStatus(status);

    if (boolStatus !== user.active) {
      dispatch(
        userActions.updateStatus({
          freightforwarderId: user._id,
          verify: status === "true",
        })
      );
    }
  };

  return (
    <div className="w-full h-screen flex flex-col no-shrink overflow-y-auto py-12">
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-1/2 m-auto bg-white"
      >
        <div className="px-6 py-4 flex justify-between">
          <div className="w-[40px] h-[40px] bg-[#16C6A4] rounded-md flex justify-center items-center">
            <AuthLogo className="h-[30px] w-auto" />
          </div>
          <div
            onClick={() => setModal(false)}
            className="w-[40px] h-[40px] rounded-md flex justify-center items-center select-none cursor-pointer"
          >
            <span className="material-icons">close</span>
          </div>
        </div>
        <div className="border-b border-gray pb-4 mb-4">
          <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm items-center font-bold">
            <div className="pr-3 shrink-none">
              BUSINESS NO.{" "}
              {user?.profile?.businessNumber
                ? `- ${user?.profile?.businessNumber}`
                : "--"}
            </div>
            {user?.active ? (
              <Tooltip content="Verified" className="!flex items-center">
                <span class="material-icons text-[#16C6A4] text-[1.3rem]">
                  check_circle
                </span>
              </Tooltip>
            ) : (
              <Tooltip content="Unverified" className="!flex items-center">
                <span class="material-icons text-[#E35328] text-[1.3rem]">
                  cancel
                </span>
              </Tooltip>
            )}
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Freight Forwarder</div>
            <div className="w-1/2 shrink-none font-bold">
              {user?.profile?.businessName || user?.fullName || "--"}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Date Joined</div>
            <div className="w-1/2 shrink-none">
              <Moment format="DD MMM, YYYY">{user?.createdAt}</Moment>
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Country</div>
            <div className="w-1/2 shrink-none">{user?.country || "--"}</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Bank Account</div>
            <div className="w-1/2 shrink-none">
              {user?.bankDetails?.length > 0
                ? user.bankDetails.map((detail, index) => (
                    <div
                      className={`${
                        index !== user.bankDetails.length - 1
                          ? "border-b border-gray pb-2"
                          : ""
                      }`}
                    >
                      <p>{detail.accountName}</p>
                      <p>{detail.accountNumber}</p>
                      <p>{detail.bankName}</p>
                    </div>
                  ))
                : "--"}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Trade Lanes</div>
            <div className="w-1/2 group shrink-none flex items-center cursor-pointer relative">
              {user.profile.tradeLanes?.length > 0 ? (
                <>
                  <span className="underline">View All</span>
                  <div className="w-[35px] h-[20px] shrink-0 flex items-center justify-center">
                    <span className="material-icons text-2xl text-gray-300">
                      keyboard_arrow_down
                    </span>
                  </div>
                  <div className="w-full group-hover:block hidden p-4 bg-white rounded-lg drop-shadow absolute top-[calc(100%+5px)]">
                    <p className="text-[#16C6A4] font-medium mb-2">
                      TRADE LANES
                    </p>
                    {user.profile.tradeLanes?.length > 0
                      ? user.profile.tradeLanes?.map((tradeLane, index) => (
                          <p>
                            {`${index + 1}. 
                            ${
                              countries?.find(
                                (c) => c.abbreviation === tradeLane.from
                              )?.name || tradeLane.from
                            } - ${
                              countries?.find(
                                (c) => c.abbreviation === tradeLane.to
                              )?.name || tradeLane.to
                            }`}
                          </p>
                        ))
                      : ""}
                  </div>
                </>
              ) : (
                "--"
              )}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Status</div>
            <div className="w-1/2 shrink-none">
              <select
                onChange={changeStatus}
                className={`w-[100px] h-[20px] appearance-none outline-none rounded-md ${
                  status ? "text-[#16C6A4]" : "text-[#E35328]"
                }`}
              >
                <option value={true} selected={user?.active}>
                  Verified
                </option>
                <option value={false} selected={!user?.active}>
                  Unverified
                </option>
              </select>
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Comments</div>
            <div className="w-1/2 shrink-none">
              This user has met all the requirement and has been accepted
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Total Earnings</div>
            <div className="w-1/2 shrink-none">{`${currencySign(
              user?.totalIncome?.currency
            )}${formatCurrency(user?.totalIncome?.value)}`}</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Phone No.</div>
            <div className="w-1/2 shrink-none">{user?.mobile}</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Email</div>
            <div className="w-1/2 shrink-none">{user?.email}</div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Address</div>
            <div className="w-1/2 shrink-none">
              {user?.profile?.businessAddress?.address || "--"}
            </div>
          </div>
          <div className="px-6 py-2 flex text-sm text-[#142837]">
            <div className="w-1/2 shrink-none">Team members</div>
            <div className="w-1/2 group shrink-none flex items-center cursor-pointer relative">
              {user?.teamMembers?.length > 0 ? (
                <>
                  <span className="underline">View All</span>
                  <div className="w-[35px] h-[20px] shrink-0 flex items-center justify-center">
                    <span className="material-icons text-2xl text-gray-300">
                      keyboard_arrow_down
                    </span>
                  </div>
                  <div className="border border-gray group-hover:block hidden mt-2 w-full p-4 rounded-md bg-white absolute top-[calc(100%+5px)] z-20 drop-shadow">
                    {user?.teamMembers?.map((u, index) => (
                      <div key={index} className="flex mb-2 items-center">
                        {u?.profile?.logo ? (
                          <Passport
                            url={u?.profile?.logo}
                            className={`w-12 h-12 shrink-0 rounded-full bg-cover bg-center border border-gray mr-2`}
                          ></Passport>
                        ) : (
                          <PassportHolder
                            bg={getColor(
                              u?.profile?.businessName || u?.fullName || "--"
                            )}
                            className={`w-12 h-12 mr-4 shrink-0 rounded-full bg-cover bg-center flex items-center justify-center ${getColor(
                              u?.profile?.businessName || u?.fullName || "--"
                            )}`}
                          >
                            <span className="text-white">
                              {getInitials(
                                u?.profile?.businessName || u?.fullName || "--"
                              )}
                            </span>
                          </PassportHolder>
                        )}
                        <div>
                          <p className="font-bold">
                            {u?.profile?.businessName || u?.fullName || "--"}
                          </p>
                          <p className="text-sm lowercase text-[#6B778C]">
                            {u?.profile?.email || u?.email || "--"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                "--"
              )}
            </div>
          </div>
        </div>
        <div className="border-b border-gray pb-4 mb-4">
          <div className="bg-[#F3F3F3] px-6 py-2 flex text-sm font-bold">
            <div className="w-1/2 shrink-none uppercase">Documents</div>
          </div>
          <div className="px-6">
            {user?.profile?.businessDocs?.filter((d) => d.file)?.length > 0 ? (
              user?.profile?.businessDocs?.map((doc, index) => (
                <UserDoc doc={doc} />
              ))
            ) : (
              <p className="text-sm pt-2 text-gray-400">
                No documents uploaded
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Passport = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

const PassportHolder = styled.div`
  background-color: ${(props) => props.bg};
`;

export default UserDetails;
