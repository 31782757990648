export const userConstants = {
  FETCH_USERS_REQUEST: "FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_ERROR: "FETCH_USERS_ERROR",
  FETCH_ADMINS_REQUEST: "FETCH_ADMINS_REQUEST",
  FETCH_ADMINS_SUCCESS: "FETCH_ADMINS_SUCCESS",
  FETCH_ADMINS_ERROR: "FETCH_ADMINS_ERROR",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
};
