import { buildQuery, currentENVData } from "../helpers";
import { authService } from "services";

export const userService = {
  getUsers,
  updateStatus,
  getAdmins,
};

function getUsers(options) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/customer/all-ff${
    options ? `?${buildQuery(options)}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then((analytics) => analytics?.data);
}

function updateStatus(user) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(user),
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/customer/verify-ff`;

  return authService.apiGate(url, requestOptions).then((rate) => rate);
}

function getAdmins(options) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/customer/all-admins${
    options ? `?${buildQuery(options)}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then((analytics) => analytics?.data);
}
