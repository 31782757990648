import React from "react";
import styled from "styled-components";
import { getColor, getInitials } from "helpers";

const Admin = ({ user }) => {
  const userName =
    user.details?.profile?.businessName || user.details?.fullName || "--";

  return (
    <div className="w-[calc((100%-40px)/3)] text-[#142837] duration-300 text-sm px-6 py-4 mr-[20px] mb-[20px] bg-white rounded-md mb-2">
      <div className="flex mb-6">
        {user.details?.profile?.logo ? (
          <Passport
            url={user.details?.profile?.logo}
            className="w-12 h-12 shrink-0 rounded-full bg-cover bg-center mr-4 border border-gray"
          ></Passport>
        ) : (
          <PassportHolder
            bg={getColor(userName)}
            className="w-12 h-12 shrink-0 rounded-full bg-cover bg-center mr-4 flex items-center justify-center"
          >
            <span className="text-white">{getInitials(userName)}</span>
          </PassportHolder>
        )}
        <div>
          <p className="font-bold text-lg">{userName}</p>
          <p className="text-sm">{user?.details?.profile?.businessType}</p>
          <p className="text-[#6B778C] text-xs">
            {user.details?.profile?.email || user.details?.email || "--"}
          </p>
        </div>
      </div>
      <p className="text-xs text-[#6B778C] border-b border-[#16C6A4] pb-2 mb-2">
        Business details
      </p>
      <div className="text-[#142837] mb-1">
        <span className="font-medium mr-2">Name:</span>
        <span>{user?.details?.profile?.businessName || "--"}</span>
      </div>
      <div className="text-[#142837] mb-1">
        <span className="font-medium mr-2">Size:</span>
        <span>Medium-sized</span>
      </div>
      <div className="text-[#142837] mb-1">
        <span className="font-medium mr-2">Location:</span>
        <span>{user.details?.profile?.businessAddress?.address || "--"}</span>
      </div>
      <div className="text-[#142837] mb-8">
        <span className="font-medium mr-2">No. of Document Uploaded:</span>
        <span>{user.details?.profile?.businessDocs?.length}/4</span>
      </div>
      <p className="text-xs text-[#16C6A4] mb-2">Teams</p>
      <div className="group relative pb-2">
        <div className="flex justify-between relative z-10">
          <div className="flex items-center">
            <div className="flex mr-2">
              {user?.freightForwarders?.length > 0 ? (
                user?.freightForwarders?.slice(0, 4)?.map((u, i) =>
                  u?.profile?.logo ? (
                    <Passport
                      url={u?.profile?.logo}
                      className={`w-12 h-12 shrink-0 rounded-full bg-cover bg-center border border-gray ${
                        i !== 0 && "-ml-6"
                      }`}
                    ></Passport>
                  ) : (
                    <PassportHolder
                      bg={getColor(
                        u?.profile?.businessName || u?.fullName || "--"
                      )}
                      className={`w-12 h-12 shrink-0 rounded-full bg-cover bg-center flex items-center justify-center ${getColor(
                        u?.profile?.businessName || u?.fullName || "--"
                      )} ${i !== 0 && "-ml-6"}`}
                    >
                      <span className="text-white">
                        {getInitials(
                          u?.profile?.businessName || u?.fullName || "--"
                        )}
                      </span>
                    </PassportHolder>
                  )
                )
              ) : (
                <i className="text-[#ACACAC] text-sm">--</i>
              )}
            </div>
            {user?.freightForwarders?.length > 4 && (
              <span className="text-[#6B778C] text-sm font-medium">
                +{user?.freightForwarders?.length - 4}
              </span>
            )}
          </div>
          <div className="h-[30px] pt-3 z-10">
            <div
              className={`flex items-center cursor-pointer select-none ${
                user?.freightForwarders?.length > 0
                  ? "cursor-pointer"
                  : "cursor-not-allowed"
              }`}
            >
              <p className="text-sm text-[#ACACAC] underline">Explore Teams</p>
              <span class="material-icons -mr-2 text-[#ACACAC]">
                chevron_right
              </span>
            </div>
          </div>
        </div>
        {user.freightForwarders?.length > 0 && (
          <div className="border border-gray group-hover:block hidden mt-2 w-[calc(100%+3rem)] p-4 rounded-md bg-white absolute -left-[1.5rem] z-20 border border-[#16C6A4]">
            {user.freightForwarders?.map((user, index) => (
              <div className="flex mb-2 items-center">
                {user?.profile?.logo ? (
                  <Passport
                    url={user?.profile?.logo}
                    className={`w-12 h-12 shrink-0 rounded-full bg-cover bg-center border border-gray mr-2`}
                  ></Passport>
                ) : (
                  <PassportHolder
                    bg={getColor(
                      user?.profile?.businessName || user?.fullName || "--"
                    )}
                    className={`w-12 h-12 mr-4 shrink-0 rounded-full bg-cover bg-center flex items-center justify-center ${getColor(
                      user?.profile?.businessName || user?.fullName || "--"
                    )}`}
                  >
                    <span className="text-white">
                      {getInitials(
                        user?.profile?.businessName || user?.fullName || "--"
                      )}
                    </span>
                  </PassportHolder>
                )}
                <div>
                  <p className="font-bold">
                    {user?.profile?.businessName || user?.fullName || "--"}
                  </p>
                  <p className="text-sm lowercase text-[#6B778C]">
                    {user?.profile?.email || user?.email || "--"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Passport = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

const PassportHolder = styled.div`
  background-color: ${(props) => props.bg};
`;

export default Admin;
