import React from "react";
import { ReactComponent as Air } from "assets/images/dashboard-icons/modes/air.svg";
import { ReactComponent as Land } from "assets/images/dashboard-icons/modes/land.svg";
import { ReactComponent as Sea } from "assets/images/dashboard-icons/modes/sea.svg";
import styled from "styled-components";
import Moment from "react-moment";

const Shipment = ({ shipment, setSelectedShipment, setModal }) => (
  <div className="w-full shrink-0 text-[#142837] cursor-pointer duration-300 hover:bg-[#ECFAF8] text-sm px-6 py-4 bg-white rounded-md flex mb-2 items-center">
    <div className="w-[20%] shrink-0 pr-2">
      <p>{shipment?.origin?.address}</p>
      <span className="material-icons text-[#142837]">arrow_downward</span>
      <p>{shipment?.destination?.address}</p>
    </div>
    <div className="w-[15%] shrink-0">
      <Moment format="ddd, DD/MM/YYYY">{shipment.createdAt}</Moment>
      <br />
      <Moment format="h:mm A">{shipment.createdAt}</Moment>
    </div>
    <div className="w-[15%] shrink-0 pr-2 truncate ...">
      {shipment?.freightForwarder?.profile?.businessName ||
        shipment?.freightForwarder?.fullName ||
        "--"}
    </div>
    <div className="w-[10%] shrink-0">
      {shipment?.mvxid && `TA${shipment?.mvxid}`}
    </div>
    <div className="w-[15%] shrink-0 capitalize">{shipment?.packageType}</div>
    <div className="w-[10%] shrink-0">
      {shipment?.serviceMode === "air" && <Air className="h-[15px] w-auto" />}
      {shipment?.serviceMode === "land" && <Land className="h-[15px] w-auto" />}
      {shipment?.serviceMode === "ocean" && <Sea className="h-[17px] w-auto" />}
    </div>
    <div className="w-[10%] shrink-0">
      <Status
        className={`text-[#1B2BBC] px-3 p-0.5 max-w-fit rounded-md select-none capitalize ${shipment?.currentStatus}`}
      >
        {shipment?.currentStatus}
      </Status>
    </div>
    <div className="w-[5%] shrink-0 flex justify-center">
      <div className="group h-[50px] relative">
        <div className="h-[40px] w-[40px] rounded flex justify-between items-center select-none cursor-pointer text-[#142837]">
          <span className="material-icons ml-auto">more_horiz</span>
        </div>
        <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none absolute -right-3 z-30">
          {/* <div className="border border-gray bg-white block mt-2 px-4 py-2 text-sm rounded select-none absolute right-0 z-30"> */}
          <div
            onClick={() => {
              setModal("view-details");
              setSelectedShipment(shipment._id);
            }}
            className="flex hover:font-medium hover:text-black mt-1 mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap"
          >
            View Details
          </div>
          <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
            Track Shipment
          </div>
          <div className="flex mb-1 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
            Contact FF
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Status = styled.div`
  &.active {
    background: rgba(27, 43, 188, 0.2);
    color: #1b2bbc;
  }
  &.pending {
    background: rgba(180, 23, 205, 0.12);
    color: #b417cd;
  }
  &.live {
    background: rgba(22, 198, 164, 0.12);
    color: #16c6a4;
  }
  &.delivered {
    background: rgba(22, 198, 164, 0.12);
    color: #16c6a4;
  }
  &.quoteAccepted {
    background: rgba(22, 198, 164, 0.12);
    color: #16c6a4;
  }
  &.quoteRejected {
    background: rgba(227, 83, 40, 0.12);
    color: #e35328;
  }
  &.cancelled {
    background: rgba(227, 83, 40, 0.12);
    color: #e35328;
  }

  background: rgba(180, 23, 205, 0.12);
  color: #b417cd;





    





`;

export default Shipment;
