import { getRemoteFileType } from "helpers";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as PDF } from "assets/images/dashboard-icons/pdf.svg";
import { ReactComponent as Image } from "assets/images/dashboard-icons/image.svg";

const UserDoc = ({ doc }) => {
  const [type, setType] = useState(null);

  const images = ["gif", "jpeg", "jpg", "png", "svg"];

  useEffect(() => {
    const fileType = getRemoteFileType(doc.file);
    setType(fileType);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="py-2 flex items-center text-sm text-[#142837]">
      <FileImage
        url={
          type !== "pdf" && !images?.includes(type)
            ? require("assets/images/dashboard-icons/file.png")
            : ""
        }
        className="w-[30px] h-[30px] mr-3 flex items-center justify-center"
      >
        {type === "pdf" ? <PDF /> : (images?.includes(type) ? <Image /> : "")}
      </FileImage>
      <div className="w-1/2 shrink-none">{doc.name}</div>
      <div className="w-1/2 shrink-none">
        {doc.name ? (
          <button className="border border-[#16C6A4] px-4 py-1 rounded-md text-sm">
            View
          </button>
        ) : (
          "---"
        )}
      </div>
    </div>
  );
};

const FileImage = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
`;

export default UserDoc;
