import React, { useCallback, useEffect, useRef, useState } from "react";

import Payment from "./Payment";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "components/common/ModalContainer";
import PaymentDetails from "./PaymentDetails";
import { usePrevious } from "helpers";
import Loading from "components/common/Loading";
import { ReactComponent as Arrow } from "assets/images/dashboard-icons/arrow.svg";
import { paymentActions } from "actions";

const PaymentList = ({ fetchData }) => {
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.payment);
  const [filter, setFilter] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);

  const prevIsVisible = usePrevious(isVisible);

  const observerTarget = useRef(null);

  const statuses = [
    {
      label: "pending",
      value: "pending",
    },
    {
      label: "paid",
      value: "paid",
    },
    {
      label: "completed",
      value: "payout-completed",
    },
  ];

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (payment?.paymentsLoading === false) {
      dispatch(paymentActions.getPayments({ ...filter, page: 1 }, true));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    if (prevIsVisible === false && isVisible === true) {
      if (payment?.payments?.pagination?.number_of_pages !== 0) {
        fetchData({ page: payment?.payments?.pagination?.next, ...filter });
      }
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [observerTarget]);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "view-details" ? (
            <PaymentDetails
              selectedPayment={
                selectedPayment &&
                payment?.payments?.data?.filter(
                  (q) => q._id === selectedPayment
                )?.[0]
              }
              setModal={setModal}
            />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      <div className="p-8">
        <div className="flex mb-6 justify-between">
          <div className="py-2 px-6 flex border border-gray rounded-md bg-white items-center text-sm text-[#142837] cursor-pointer select-none">
            <Arrow className="mr-2 h-[16px] w-auto" />
            Download list
          </div>
          <div className="flex">
            {statuses.map((status, index) => (
              <div
                key={index}
                onClick={() =>
                  setFilter((f) => {
                    if (status.value === filter.status) {
                      delete f.status;
                    } else {
                      f.status = status.value;
                    }

                    return {
                      ...f,
                    };
                  })
                }
                className={`py-2 px-6 mr-1 flex border rounded-md bg-white items-center text-sm cursor-pointer select-none capitalize ${
                  status.value === filter.status
                    ? "border-[#16C6A4] text-[#16C6A4]"
                    : "border-gray  text-gray-400"
                }`}
              >
                {status.label}
              </div>
            ))}
          </div>
          <div className="flex rounded-md bg-white items-center px-4 text-[#E35328] text-sm">
            <div className="py-0.5 px-2 mr-3 flex border border-gray rounded-md bg-[#E353281C]/[0.11] items-center text-sm cursor-pointer select-none">
              {payment?.payments?.data?.length}
            </div>
            All
          </div>
        </div>
        <div className="w-full overflow-x-auto pb-20">
          <div className="w-full h-[50px] text-white text-sm px-6 bg-[#16C6A4] rounded-md flex items-center mb-2">
            <div className="w-[10%] shrink-0">Shipment ID</div>
            <div className="w-[25%] shrink-0">Freight forwarder</div>
            <div className="w-[20%] shrink-0">Date of payment</div>
            <div className="w-[20%] shrink-0">Amount</div>
            <div className="w-[10%] shrink-0">Type</div>
            <div className="w-[10%] shrink-0">Status</div>
            <div className="w-[5%] shrink-0"></div>
          </div>
          {payment?.payments?.data ? (
            <>
              {payment?.payments?.data?.length > 0 ? (
                <>
                  {payment?.payments?.data.map((p, index) => (
                    <React.Fragment key={index}>
                      <Payment
                        payment={p}
                        setModal={setModal}
                        setSelectedPayment={setSelectedPayment}
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <p className="pt-20 text-center">No payments</p>
              )}
              <div className="relative w-full h-[50px] flex items-center justify-center mt-8">
                {payment?.paymentsLoading && (
                  <Loading size={30} color="black m-auto" />
                )}
              </div>
            </>
          ) : (
            <p className="w-full text-center pt-10">Loading...</p>
          )}
          <div ref={observerTarget}></div>
        </div>
      </div>
    </>
  );
};

export default PaymentList;
