import { userActions } from "actions";
import { currencySign, formatCurrency, getCountryfromLatLng } from "helpers";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";

const User = ({ map, user, setSelectedUser, setModal }) => {
  const dispatch = useDispatch();
  const userSelector = useSelector((state) => state.user);
  const [country, setCountry] = useState("");

  const getGeoLocation = async () => {
    let country = await getCountryfromLatLng({
      lat: user?.profile?.businessAddress?.lat,
      lng: user?.profile?.businessAddress?.lng,
    });

    setCountry(
      country.details?.find((c) => c.types.includes("country"))?.long_name
    );
  };

  useEffect(() => {
    if (map) {
      getGeoLocation();
    }
    // eslint-disable-next-line
  }, [map]);

  const changeStatus = (e) => {
    const { value: status } = e.target;
    const boolStatus = status === "true";

    if (boolStatus !== user.active) {
      dispatch(
        userActions.updateStatus({
          freightforwarderId: user._id,
          verify: status === "true",
        })
      );
    }
  };

  return (
    <div className="w-full text-[#142837] cursor-pointer duration-300 hover:bg-[#ECFAF8] text-sm px-6 py-4 bg-white rounded-md flex mb-2 items-center relative">
      {userSelector.updateUserLoading === user._id && (
        <div className="w-full h-full absolute z-10 top-0 left-0 bg-white/50"></div>
      )}
      <div className="w-[15%] shrink-0 pr-2 break-all">
        {user?.profile?.businessNumber || "--"}
      </div>
      <div className="w-[20%] shrink-0 pr-2 break-word overflow-hidden">
        {user?.profile?.businessName || user?.fullName || "--"}
      </div>
      <div className="w-[15%] shrink-0">
        <Moment format="ddd, DD/MM/YYYY">{user?.createdAt}</Moment>
      </div>
      <div className="w-[15%] shrink-0">
        <select
          value={user?.active}
          onChange={changeStatus}
          className={`w-[80%] h-[30px] appearance-none outline-none border border-gray rounded-md pl-2 ${
            user?.active ? "text-[#16C6A4]" : "text-[#E35328]"
          }`}
        >
          <option value={true}>Verified</option>
          <option value={false}>Unverified</option>
        </select>
      </div>
      <div className="w-[15%] shrink-0 capitalize">
        {user?.profile?.businessAddress?.address ? country : "--"}
      </div>
      <div className="w-[15%] shrink-0">
        <div className="px-2 truncate ... py-1 bg-[#D9D9D9]/[0.25] border border-[#16C6A4] text-[#16C6A4] max-w-fit">
          {user?.totalIncome?.currency
            ? `${currencySign(user?.totalIncome?.currency)}${formatCurrency(
                user?.totalIncome?.value
              )}`
            : ""}
        </div>
      </div>
      <div className="w-[5%] shrink-0 flex justify-center">
        <div className="group h-[50px] relative">
          <div className="h-[40px] w-[40px] rounded flex justify-between items-center select-none cursor-pointer text-[#142837]">
            <span className="material-icons ml-auto">more_horiz</span>
          </div>
          <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none absolute -right-3 z-30">
            <div
              onClick={() => {
                setModal("view-details");
                setSelectedUser({ ...user, country: country });
              }}
              className="flex hover:font-medium hover:text-black mt-1 mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap"
            >
              View Details
            </div>
            <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
              Contact User
            </div>
            <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
              Change Status
            </div>
            <div className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between whitespace-nowrap">
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
