/* eslint-disable */
import { rateActions } from "actions";
import { debounce, removeArrayItem } from "helpers";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import Moment from "react-moment";

const Step1 = ({ rate, setRate }) => {
  const dispatch = useDispatch();
  const rateSelector = useSelector((state) => state.rate);

  useEffect(() => {
    setRate((rate) => {
      if (rate?.originPort) {
        const foundRate = rateSelector.originPorts?.find(
          (p) => p.portCode === rate?.originPort
        );

        document.getElementById("origin").value = foundRate
          ? foundRate?.portName
          : rate?.originPort;
      }

      if (rate?.destinationPort) {
        const foundRate = rateSelector.destinationPorts?.find(
          (p) => p.portCode === rate?.destinationPort
        );

        document.getElementById("destination").value = foundRate
          ? foundRate?.portName
          : rate?.destinationPort;
      }

      return {
        ...rate,
      };
    });
  }, []);

  const onChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setRate((rate) => ({
      ...rate,
      [name]: value,
    }));
  };

  const searchPort = (e, source) => {
    const { value } = e.target;

    dispatch(rateActions.searchPort(source, value));
  };

  const debouncedPortSearch = useCallback(debounce(searchPort, 500), []);

  const searchProduct = (e) => {
    const { value } = e.target;

    dispatch(rateActions.searchProduct(value));
  };

  const debouncedProductSearch = useCallback(debounce(searchProduct, 500), []);

  return (
    <>
      <p className="text-lg text-[#16C6A4] font-medium mb-2">Route details</p>
      <p className="text-sm mb-4">
        Provide the following details about the route details for your shipment
      </p>
      <div className="flex justify-between border-b border-gray pb-8 mb-10">
        <div className="w-[45%] shrink-0">
          <p className="font-medium mb-3">Origin</p>
          <div className="w-full h-[60px] group relative z-20">
            <div className="flex border border-[#DFE1E6] rounded-md overflow-hidden">
              <input
                type={"text"}
                id="origin"
                className="focus:border-[#16C6A4] duration-300 w-full py-3 px-2 bg-none outline-none text-sm"
                placeholder="Enter origin"
                onChange={(e) => debouncedPortSearch(e, "origin")}
              />
              <div className="w-[35px] shrink-0 flex items-center justify-center">
                <span className="material-icons text-2xl text-gray-300">
                  keyboard_arrow_down
                </span>
              </div>
            </div>
            {(rateSelector.originPortsLoading || rateSelector.originPorts) && (
              <div className="p-4 border border-gray group-hover:block hidden rounded-md mt-2 bg-white max-h-[calc(100vh-400px)] overflow-y-auto">
                {rateSelector.originPortsLoading &&
                !rateSelector.originPorts ? (
                  <p className="w-full h-full text-center text-sm">
                    Searching available ports
                  </p>
                ) : !rateSelector.originPortsLoading &&
                  rateSelector.originPorts?.length === 0 ? (
                  <p className="w-full h-full text-center text-sm">
                    No available ports
                  </p>
                ) : (
                  rateSelector.originPorts?.map((r, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        document.getElementById("origin").value = r.portName;
                        setRate((rate) => ({
                          ...rate,
                          originPort: r.portCode,
                        }));
                      }}
                      className={`flex p-2 -mt-1 ${
                        index === rateSelector.originPorts?.length - 1
                          ? "mb-0"
                          : "border-b border-gray py-4"
                      } ${
                        rate.originPort === r.portCode &&
                        "bg-[#ebfaf6] border-none rounded-md"
                      }`}
                    >
                      <div className="w-[25px] h-[25px] border border-gray shrink-0 mr-4 rounded-full overflow-hidden flex items-center justify-center">
                        <ReactCountryFlag
                          countryCode={r.country}
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          style={{
                            width: "30px",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div className="w-full text-sm cursor-pointer">
                        <p className="mb-1">{r.portName}</p>
                        <div className="flex text-xs text-gray-500">
                          <p>{r.country_long}</p>
                          <span className="px-4">|</span>
                          <p>{r.portContinent}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
        <div className="w-[45%] shrink-0">
          <p className="font-medium mb-3">Destination</p>
          <div className="w-full h-[60px] group relative z-20">
            <div className="flex border border-[#DFE1E6] rounded-md overflow-hidden">
              <input
                type={"text"}
                id="destination"
                className="focus:border-[#16C6A4] duration-300 w-full py-3 px-2 bg-none outline-none text-sm"
                placeholder="Enter destination"
                onChange={(e) => debouncedPortSearch(e, "destination")}
              />
              <div className="w-[35px] shrink-0 flex items-center justify-center">
                <span className="material-icons text-2xl text-gray-300">
                  keyboard_arrow_down
                </span>
              </div>
            </div>
            {(rateSelector.destinationPortsLoading ||
              rateSelector.destinationPorts) && (
              <div className="p-4 border border-gray group-hover:block hidden rounded-md mt-2 bg-white max-h-[calc(100vh-400px)] overflow-y-auto">
                {rateSelector.destinationPortsLoading &&
                !rateSelector.destinationPorts ? (
                  <p className="w-full h-full text-center text-sm">
                    Searching available ports
                  </p>
                ) : !rateSelector.destinationPortsLoading &&
                  rateSelector.destinationPorts?.length === 0 ? (
                  <p className="w-full h-full text-center text-sm">
                    No available ports
                  </p>
                ) : (
                  rateSelector.destinationPorts?.map((r, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        document.getElementById("destination").value =
                          r.portName;
                        setRate((rate) => ({
                          ...rate,
                          destinationPort: r.portCode,
                        }));
                      }}
                      className={`flex p-2 -mt-1 ${
                        index === rateSelector.destinationPorts?.length - 1
                          ? "mb-0"
                          : "border-b border-gray py-4"
                      } ${
                        rate.destinationPort === r.portCode &&
                        "bg-[#ebfaf6] border-none rounded-md"
                      }`}
                    >
                      <div className="w-[25px] h-[25px] border border-gray shrink-0 mr-4 rounded-full overflow-hidden flex items-center justify-center">
                        <ReactCountryFlag
                          countryCode={r.country}
                          svg
                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                          style={{
                            width: "30px",
                            height: "auto",
                          }}
                        />
                      </div>
                      <div className="w-full text-sm cursor-pointer">
                        <p className="mb-1">{r.portName}</p>
                        <div className="flex text-xs text-gray-500">
                          <p>{r.country_long}</p>
                          <span className="px-4">|</span>
                          <p>{r.portContinent}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="text-lg text-[#16C6A4] font-medium mb-2">Cargo details</p>
      <p className="text-sm mb-4">
        Provide the following details about the type of cargo the rate is for
      </p>
      <div className="flex justify-between pb-4">
        <div className="w-[45%] shrink-0">
          <div className="font-medium mb-3 flex items-center">
            Product{rate?.product?.length > 1 ? "s" : ""}
            {rate?.product?.length ? (
              <p className="px-2 py-0.5 bg-black text-white text-sm flex items-center justify-center ml-2 rounded-full">
                {rate?.product?.length}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="w-full h-[60px] group relative z-10">
            <div className="flex border border-[#DFE1E6] rounded-md overflow-hidden">
              <input
                type={"text"}
                className="focus:border-[#16C6A4] duration-300 w-full py-3 px-2 bg-none outline-none text-sm"
                placeholder="Select commodity type or HS code"
                onChange={debouncedProductSearch}
              />
              <div className="w-[35px] shrink-0 flex items-center justify-center">
                <span className="material-icons text-2xl text-gray-300">
                  keyboard_arrow_down
                </span>
              </div>
            </div>
            {(rateSelector.productsLoading || rateSelector.products) && (
              <div className="p-4 border border-gray group-hover:block hidden rounded-md mt-2 bg-white max-h-[calc(100vh-400px)] overflow-y-auto">
                {rateSelector.productsLoading && !rateSelector.products ? (
                  <p className="w-full h-full text-center text-sm">
                    Searching available ports
                  </p>
                ) : !rateSelector.productsLoading &&
                  rateSelector.products?.length === 0 ? (
                  <p className="w-full h-full text-center text-sm">
                    No available ports
                  </p>
                ) : (
                  rateSelector.products?.map((r, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setRate((rate) => {
                          const product = {
                            code: r.productCode.toString(),
                            name: r.Description,
                          };

                          if (!rate.product) rate.product = [];

                          if (
                            rate.product
                              ?.map((a) => JSON.stringify(a))
                              .includes(JSON.stringify(product))
                          ) {
                            rate.product = removeArrayItem(
                              rate.product,
                              product,
                              "code"
                            );
                          } else {
                            rate.product?.push(product);
                          }

                          return {
                            ...rate,
                          };
                        });
                      }}
                      className={`flex p-2 -mt-1 ${
                        index === rateSelector.products?.length - 1
                          ? "mb-0"
                          : "border-b border-gray py-4"
                      } ${
                        rate.product
                          ?.map((a) => JSON.stringify(a))
                          .includes(
                            JSON.stringify({
                              code: r.productCode.toString(),
                              name: r.Description,
                            })
                          ) && "bg-[#ebfaf6] border-none rounded-md"
                      }`}
                    >
                      <div className="w-full text-sm cursor-pointer">
                        <p className="mb-1">{r.Description}</p>
                        <div className="flex text-xs text-gray-500">
                          <p>
                            <i className="mr-2">Valid till</i>
                            <Moment format="DD-MM-YYYY">
                              {r.dateOfValidity}
                            </Moment>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <div>
            {rate?.product?.map((r, index) => (
              <div
                key={index}
                className="text-xs bg-black text-white py-2 px-4 rounded-md mr-2 mb-2 w-fit float-left relative"
              >
                {r.name}
                <div
                  onClick={() => {
                    setRate((rate) => {
                      if (rate.product.includes(r)) {
                        rate.product = removeArrayItem(rate.product, r, "code");
                      }

                      return {
                        ...rate,
                      };
                    });
                  }}
                  className="w-[20px] h-[20px] rounded-full border-2 border-black bg-white absolute -top-1 -right-1 cursor-pointer flex items-center justify-center"
                >
                  <span className="material-icons text-lg text-black">
                    remove
                  </span>
                </div>
              </div>
            ))}
            <div className="clear-none"></div>
          </div>
        </div>
        <div className="w-[45%] shrink-0">
          <p className="font-medium mb-3">Service type</p>
          <select
            name="serviceType"
            value={rate.serviceType || ""}
            onChange={onChange}
            className={`appearance-none border border-gray mb-4 rounded-md p-3 outline-0 w-full h-12 text-sm text-gray-800 invalid:text-gray-400`}
            required
          >
            <option value="">Choose type</option>
            <option value="import">Import</option>
            <option value="export">Export</option>
          </select>
        </div>
      </div>
      <div className="flex justify-between border-b border-gray pb-10 mb-10">
        <div className="w-[45%] shrink-0">
          <p className="font-medium mb-3">Mode of movement</p>
          <select
            name="movementMode"
            value={rate.movementMode || ""}
            onChange={onChange}
            className={`appearance-none border border-gray mb-4 rounded-md p-3 outline-0 w-full h-12 text-sm text-gray-800 invalid:text-gray-400`}
            required
          >
            <option value="">Choose mode</option>
            <option value="land">Land freight</option>
            <option value="ocean">Sea freight</option>
            <option value="air">Air freight</option>
          </select>
        </div>
      </div>
      <p className="text-lg text-[#16C6A4] font-medium mb-2">Movement type</p>
      <p className="text-sm mb-4">
        Select the type of movement for your shipment that is specific to your
        rate
      </p>
      <div className="pb-10">
        <div
          className={`flex w-full rounded-md cursor-pointer items-center justify-between border p-6 pb-4 mb-4 ${
            rate.movementType === "door-to-door"
              ? "border-black"
              : "border-gray"
          }`}
          onClick={() =>
            setRate((rate) => ({
              ...rate,
              movementType: "door-to-door",
            }))
          }
        >
          <div className="w-2/5 pb-3">
            <p className="text-lg font-medium text-[#142837]">
              Door - to - Door Shipping
            </p>
            <p className="text-sm text-[#6B778C]">
              Goods are transported to the sender's location to the recepient's
              location
            </p>
          </div>
          <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mb-3">
            {rate.movementType === "door-to-door" && (
              <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
            )}
          </div>
        </div>
        <div
          className={`flex w-full rounded-md cursor-pointer items-center justify-between border p-6 pb-4 mb-4 ${
            rate.movementType === "door-to-port"
              ? "border-black"
              : "border-gray"
          }`}
          onClick={() =>
            setRate((rate) => ({
              ...rate,
              movementType: "door-to-port",
            }))
          }
        >
          <div className="w-2/5 pb-3">
            <p className="text-lg font-medium text-[#142837]">
              Door - to - Port Shipping
            </p>
            <p className="text-sm text-[#6B778C]">
              Goods are transported to the sender’s location to the specific
              port
            </p>
          </div>
          <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mb-3">
            {rate.movementType === "door-to-port" && (
              <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
            )}
          </div>
        </div>
        <div
          className={`flex w-full rounded-md cursor-pointer items-center justify-between border p-6 pb-4 mb-4 ${
            rate.movementType === "port-to-port"
              ? "border-black"
              : "border-gray"
          }`}
          onClick={() =>
            setRate((rate) => ({
              ...rate,
              movementType: "port-to-port",
            }))
          }
        >
          <div className="w-2/5 pb-3">
            <p className="text-lg font-medium text-[#142837]">
              Port - to - Port Shipping
            </p>
            <p className="text-sm text-[#6B778C]">
              Goods are transported between two specified ports
            </p>
          </div>
          <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mb-3">
            {rate.movementType === "port-to-port" && (
              <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
            )}
          </div>
        </div>
        <div
          className={`flex w-full rounded-md cursor-pointer items-center justify-between border p-6 pb-4 mb-4 ${
            rate.movementType === "port-to-door"
              ? "border-black"
              : "border-gray"
          }`}
          onClick={() =>
            setRate((rate) => ({
              ...rate,
              movementType: "port-to-door",
            }))
          }
        >
          <div className="w-2/5 pb-3">
            <p className="text-lg font-medium text-[#142837]">
              Port - to - Door Shipping
            </p>
            <p className="text-sm text-[#6B778C]">
              Goods are transported to the sender's location to the recepient's
              location
            </p>
          </div>
          <div className="h-[25px] w-[25px] flex items-center justify-center rounded-full overflow-hidden border-2 border-black mb-3">
            {rate.movementType === "port-to-door" && (
              <div className="h-[17px] w-[17px] rounded-full bg-black"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
