import React from "react";
import { ReactComponent as SearchIcon } from "assets/images/notification-icons/search.svg";
import { ReactComponent as MessagesIcon } from "assets/images/notification-icons/messages.svg";
import { ReactComponent as AlarmIcon } from "assets/images/notification-icons/alarm.svg";
import { Outlet, useLocation } from "react-router-dom";
import routes from "routes";
import DashboardSidebar from "components/common/Sidebar";

const Dashboard = () => {
  const location = useLocation();

  const rate = routes?.find(
    (r) =>
      location.pathname === `/dashboard/${r.path}` ||
      r?.children?.find((c) => location.pathname === `/dashboard/${c.path}`)
  );

  return (
    <div className="w-full h-screen flex">
      <DashboardSidebar routes={routes} />
      <div className="w-[calc(100%-300px)] h-screen flex flex-col bg-[#F4F4F4]">
        <div className="px-6 h-[60px] shrink-0 w-full flex justify-between shadow-md bg-white">
          <p className="h-full flex font-medium items-center text-xl">
            {rate ? rate?.name : ""}
          </p>
          <div className="flex h-full items-center">
            <div className="w-[40px] h-[40px] cursor-pointer flex items-center justify-center rounded-full mr-2">
              <SearchIcon className="h-[18px] w-auto" />
            </div>
            <div className="w-[40px] h-[40px] cursor-pointer flex items-center justify-center rounded-full mr-2">
              <MessagesIcon className="h-[16px] w-auto" />
            </div>
            <div className="w-[40px] h-[40px] cursor-pointer flex items-center justify-center rounded-full">
              <AlarmIcon className="h-[20px] w-auto" />
            </div>
          </div>
        </div>
        <div className="h-full overflow-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
