import React, { useCallback, useEffect, useRef, useState } from "react";
import Admin from "./Admin";
import { usePrevious } from "helpers";

const AdminStats = ({ user, fetchData }) => {
  const [isVisible, setIsVisible] = useState(false);

  const prevIsVisible = usePrevious(isVisible);

  const observerTarget = useRef(null);

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (prevIsVisible === false && isVisible === true) {
      fetchData(user?.admins?.pagination?.next);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [observerTarget]);

  return (
    <div className="px-8 flex mb-6 w-full">
      <div className="bg-[#16C6A4]/10 flex flex-wrap p-6 rounded-lg w-full [&>*:nth-child(3n)]:mr-0">
        {user?.admins?.data ? (
          <>
            {user?.admins?.data.map((user, index) => (
              <Admin user={user} />
            ))}
            <div ref={observerTarget}></div>
          </>
        ) : (
          <p className="w-full text-center">Loading...</p>
        )}
      </div>
    </div>
  );
};

export default AdminStats;
