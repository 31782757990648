import React, { useEffect, useRef, useState } from "react";
import QuoteAnalytics from "./Analytics";
import QuoteList from "./List";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { quoteActions } from "actions/quoteActions";

const Quote = () => {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [step, setStep] = useState(1);
  const [offset, setOffset] = useState({ width: 0, left: 0 });
  const item1 = useRef();
  const item2 = useRef();

  useEffect(() => {
    dispatch(quoteActions.getAnalytics({ groupBy: "month" }));

    if (quote?.quotesLoading === false) {
      dispatch(quoteActions.getQuotes({}, true));
    }
    // eslint-disable-next-line
  }, []);

  const fetchData = (options) => {
    if (quote?.quotesLoading === false) {
      dispatch(quoteActions.getQuotes(options));
    }
  };

  useEffect(() => {
    if (step === 1) {
      setOffset({
        width: item1?.current?.offsetWidth,
        left: 0,
      });
    }
    if (step === 2) {
      setOffset({
        width: item2?.current?.offsetWidth,
        left: item1?.current?.offsetWidth,
      });
    }
  }, [step]);

  return (
    <>
      <div className="p-8 pb-0 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg select-none cursor-pointer overflow-hidden">
          <div className="relative h-[35px] flex">
            <div
              className={`h-[35px] bg-[#16C6A4] absolute top-0 duration-300`}
              style={{ width: `${offset.width}px`, left: `${offset.left}px` }}
            ></div>
            <div
              ref={item1}
              onClick={() => setStep(1)}
              className={`h-[35px] duration-300 flex items-center px-8 relative ${
                step === 1 ? "text-white" : "text-[#142837]"
              }`}
            >
              Analytics
            </div>
            <div
              ref={item2}
              onClick={() => setStep(2)}
              className={`h-[35px] duration-300 flex items-center px-8 relative ${
                step === 2 ? "text-white" : "text-[#142837]"
              }`}
            >
              Quotes
            </div>
          </div>
        </div>
        <div className="text-right">
          <p className="text-xs">Today</p>
          <p className="font-bold">
            <Moment format="MMMM DD, YYYY"></Moment>
          </p>
        </div>
      </div>
      {step === 1 ? <QuoteAnalytics /> : <QuoteList fetchData={fetchData} />}
    </>
  );
};

export default Quote;
