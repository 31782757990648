import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as ChartIcon } from "assets/images/dashboard-icons/chart-3.svg";
import { ReactComponent as Select } from "assets/images/dashboard-icons/select-dropdown.svg";
import { ReactComponent as Check } from "assets/images/dashboard-icons/check.svg";
import { ReactComponent as Air } from "assets/images/dashboard-icons/modes/air.svg";
import { ReactComponent as Land } from "assets/images/dashboard-icons/modes/land.svg";
import { ReactComponent as Sea } from "assets/images/dashboard-icons/modes/sea.svg";
import Shipment from "./Shipment";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "components/common/ModalContainer";
import ShipmentDetails from "./ShipmentDetails";
import Loading from "components/common/Loading";
import { usePrevious } from "helpers";
import { shipmentActions } from "actions";
import moment from "moment";

const ShipmentList = ({ fetchData }) => {
  const dispatch = useDispatch();
  const shipment = useSelector((state) => state.shipment);
  const [filter, setFilter] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const prevIsVisible = usePrevious(isVisible);

  const statuses = [
    {
      label: "pending",
      value: "pending",
    },
    {
      label: "live",
      value: "live",
    },
    {
      label: "In transit",
      value: "in-transit",
    },
    {
      label: "pickup",
      value: "pickup",
    },
    {
      label: "quote sent",
      value: "quote-sent",
    },
    {
      label: "delivered",
      value: "delivered",
    },
    {
      label: "quote accepted",
      value: "quoteAccepted",
    },
    {
      label: "quote rejected",
      value: "quoteRejected",
    },
    {
      label: "cancelled",
      value: "cancelled",
    },
    {
      label: "loaded",
      value: "loaded",
    },
  ];

  const modes = [
    {
      label: "air",
      value: "air",
      icon: <Air className="h-[15px] w-auto" />,
    },
    {
      label: "land",
      value: "land",
      icon: <Land className="h-[15px] w-auto" />,
    },
    {
      label: "sea",
      value: "ocean",
      icon: <Sea className="h-[17px] w-auto" />,
    },
  ];

  const observerTarget = useRef(null);

  const callbackFunction = useCallback((entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    if (shipment?.shipmentsLoading === false) {
      dispatch(shipmentActions.getShipments({ ...filter, page: 1 }, true));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    if (prevIsVisible === false && isVisible === true) {
      if (shipment?.shipments?.pagination?.number_of_pages !== 0) {
        fetchData({ page: shipment?.shipments?.pagination?.next, ...filter });
      }
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: 1,
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        // eslint-disable-next-line
        observer.unobserve(observerTarget.current);
      }
    };
    // eslint-disable-next-line
  }, [observerTarget]);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "view-details" ? (
            <ShipmentDetails
              selectedShipment={
                selectedShipment &&
                shipment?.shipments?.data?.filter(
                  (s) => s._id === selectedShipment
                )?.[0]
              }
              setModal={setModal}
            />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      <div className="p-8">
        <div className="flex mb-8 relative z-10">
          <div className="h-[40px] w-[40px] rounded-l mr-2 bg-[#16C6A4] flex items-center justify-center">
            <ChartIcon className="h-[10px] w-auto" />
          </div>
          <div className="h-[40px] px-6 pr-3 flex bg-white mr-2">
            <div className="flex items-center mr-4">
              <div className="bg-[#1B2BBC]/20 rounded px-2 py-0.5 text-sm">
                {shipment?.shipments?.data?.length}
              </div>
            </div>
            <div className="group h-[50px]">
              <div className="h-[40px] w-[170px] rounded flex justify-between select-none cursor-pointer text-[#142837]">
                <div className="pl-4 flex items-center text-sm capitalize">
                  {filter.status
                    ? statuses.find((s) => s.value === filter.status)?.label
                    : "All"}
                </div>
                <div className="h-[40px] w-[40px] flex items-center justify-center">
                  <Select />
                </div>
              </div>
              <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none">
                <p className="text-sm pb-2 mb-3 border-b border-gray">
                  <span className="text-[#E35328]">Filter by:</span>{" "}
                  <span className="text-[#6B778C]">Status</span>
                </p>
                {filter?.status ? (
                  <div
                    onClick={() =>
                      setFilter((f) => {
                        delete f.status;

                        return {
                          ...f,
                        };
                      })
                    }
                    className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer"
                  >
                    All
                  </div>
                ) : (
                  ""
                )}

                {statuses
                  ?.filter((s) => s.value !== filter.status)
                  .map((status, index) => (
                    <div
                      key={index}
                      className="flex mb-2 items-center text-[#6B778C] font-medium cursor-pointer capitalize"
                      onClick={() =>
                        setFilter((f) => ({
                          ...f,
                          status: status.value,
                        }))
                      }
                    >
                      {status.label}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="h-[40px] px-4 pr-6 mr-2 flex bg-white">
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
                setFilter((f) => {
                  if (update[0]) {
                    f.startDate = moment(update[0])?.format("DD-MM-YYYY");
                  }
                  if (update[1]) {
                    f.endDate = moment(update[1])?.format("DD-MM-YYYY");
                  }

                  return {
                    ...f,
                  };
                });
              }}
              withPortal
              placeholderText="Choose date range"
            />
          </div>
          <div className="h-[40px] pl-3 pr-3 flex bg-white mr-2">
            <div className="group h-[50px]">
              <div className="h-[40px] w-[130px] rounded flex justify-between select-none cursor-pointer text-[#142837]">
                <div className="pl-4 flex items-center text-sm capitalize">
                  {filter.search
                    ? modes.find((s) => s.value === filter.search)?.label
                    : "All modes"}
                </div>
                <div className="h-[40px] w-[40px] flex items-center justify-center">
                  <Select />
                </div>
              </div>
              <div className="border border-gray bg-white group-hover:block hidden mt-2 px-4 py-2 text-sm rounded select-none">
                {filter?.search ? (
                  <div
                    onClick={() =>
                      setFilter((f) => {
                        delete f.search;

                        return {
                          ...f,
                        };
                      })
                    }
                    className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer"
                  >
                    All modes
                  </div>
                ) : (
                  ""
                )}
                {modes
                  ?.filter((s) => s.value !== filter.search)
                  .map((mode, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        setFilter((f) => ({
                          ...f,
                          search: mode.value,
                        }))
                      }
                      className="flex mb-3 items-center text-[#6B778C] font-medium cursor-pointer flex justify-between"
                    >
                      <span className="capitalize">{mode.label}</span>
                      <div className="w-[20px] h-[15px]">{mode.icon}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="h-[40px] w-[40px] rounded-r bg-[#16C6A4] flex items-center justify-center">
            <Check className="h-[15px] w-auto cursor-pointer" />
          </div>
        </div>
        <div className="w-full overflow-x-auto pb-20">
          <div className="w-full h-[50px] text-white text-sm px-6 bg-[#16C6A4] rounded-md flex items-center mb-2">
            <div className="w-[20%] shrink-0">Route</div>
            <div className="w-[15%] shrink-0">Date Created</div>
            <div className="w-[15%] shrink-0">Freight forwarder</div>
            <div className="w-[10%] shrink-0">Shipment ID</div>
            <div className="w-[15%] shrink-0">Package type</div>
            <div className="w-[10%] shrink-0">Mode</div>
            <div className="w-[10%] shrink-0">Status</div>
            <div className="w-[5%] shrink-0"></div>
          </div>
          {shipment?.shipments?.data ? (
            <>
              {shipment?.shipments?.data?.length > 0 ? (
                <>
                  {shipment?.shipments?.data.map((s, index) => (
                    <React.Fragment key={index}>
                      <Shipment
                        shipment={s}
                        setModal={setModal}
                        setSelectedShipment={setSelectedShipment}
                      />
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <p className="pt-20 text-center">No shipments</p>
              )}
              <div className="relative w-full h-[50px] flex items-center justify-center mt-8">
                {shipment?.shipmentsLoading && (
                  <Loading size={30} color="black m-auto" />
                )}
              </div>
            </>
          ) : (
            <p className="w-full text-center pt-10">Loading...</p>
          )}
          <div ref={observerTarget}></div>
        </div>
      </div>
    </>
  );
};

export default ShipmentList;
